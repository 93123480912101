var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title:
                  _vm.appName +
                  " - Edit Service Location: " +
                  (_vm.serviceLocation.name || "-")
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: {
                    name: "service-locations-show",
                    params: { serviceLocation: _vm.serviceLocation.id }
                  }
                }
              },
              [_vm._v("Back to service location")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Service locations")
                        ]),
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Edit service location")
                        ]),
                        _c("service-location-form", {
                          attrs: {
                            errors: _vm.form.$errors,
                            id: _vm.serviceLocation.id,
                            name: _vm.form.name,
                            regular_opening_hours:
                              _vm.form.regular_opening_hours,
                            holiday_opening_hours:
                              _vm.form.holiday_opening_hours
                          },
                          on: {
                            "update:name": function($event) {
                              return _vm.$set(_vm.form, "name", $event)
                            },
                            "update:regular_opening_hours": function($event) {
                              return _vm.$set(
                                _vm.form,
                                "regular_opening_hours",
                                $event
                              )
                            },
                            "update:holiday_opening_hours": function($event) {
                              return _vm.$set(
                                _vm.form,
                                "holiday_opening_hours",
                                $event
                              )
                            },
                            "update:image_file_id": function($event) {
                              _vm.form.image_file_id = $event
                            },
                            clear: function($event) {
                              return _vm.form.$errors.clear($event)
                            },
                            "alt-text-changed": function($event) {
                              _vm.altTextChanged = true
                            },
                            "image-changed": function($event) {
                              _vm.imageChanged = true
                            }
                          }
                        }),
                        _c("gov-warning-text", [
                          _vm._v(
                            "\n            Please be aware, by submitting these changes, any pending updates\n            may be overwritten.\n          "
                          )
                        ]),
                        _vm.form.$submitting
                          ? _c(
                              "gov-button",
                              { attrs: { disabled: "", type: "submit" } },
                              [_vm._v("Requesting...")]
                            )
                          : _c(
                              "gov-button",
                              {
                                attrs: { type: "submit" },
                                on: { click: _vm.onSubmit }
                              },
                              [_vm._v(_vm._s(_vm.updateButtonText))]
                            ),
                        _vm.form.$errors.any()
                          ? _c("ck-submit-error")
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }